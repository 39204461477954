@import url('https://fonts.googleapis.com/css2?family=Castoro:ital@0;1&display=swap');
.containerServices {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 150vh; /* Adjust height as needed */
    background-color: black; /* Set background color to black */
    color: white; /* Set text color to white */
  }
  
  .contentServices {
    margin-top: 10px; /* Adjust margin as needed */
    text-align: center;
    font-family: "Castoro", serif;
    font-size: 40px;
  }
  .hero-headingServices{
    color: white; /* Change text color to white */
    font-family: "Castoro", serif;
    font-size: 40px;
    text-align: center;
  }
  
  .imageContainer {
    margin-top: 30px; /* Adjust margin as needed */
    display: flex;
    justify-content: center;
    
  }
  
  .imageContainer img {
    width: 900px; /* Set width in pixels */
    height: auto; /* Maintain aspect ratio */
  }

  /* Media queries for smaller screens */
@media (max-width: 480px) {
  .containerServices {
      height: 50vh; /* Reset height for smaller screens */
      
  }
  
  .contentServices {
      font-size: 30px; /* Adjust font size for smaller screens */
  }
  
  .hero-headingServices {
      font-size: 30px; /* Adjust font size for smaller screens */
  }
  
  .imageContainer img {
      width: 100%; /* Set image width to 100% for smaller screens */
  }
}

/* Media queries for medium-sized screens */
@media (max-width: 768px) {
  .contentServices {
      font-size: 35px; /* Adjust font size for medium-sized screens */
  }
  
  .hero-headingServices {
      font-size: 35px; /* Adjust font size for medium-sized screens */
  }
}

/* Media queries for larger screens */
@media (max-width: 1024px) {
  .imageContainer img {
      max-width: 80%; /* Adjust max width for larger screens */
  }
}