/* Footer.css */

@import url('https://fonts.googleapis.com/css2?family=Castoro:ital@0;1&display=swap');

  footer {
    background-color: #000000;
    padding: 20px;
    text-align: center;
    font-family: "Castoro", serif;
    color: rgb(255, 255, 255); /* Text color */
  }

  /* Media queries for smaller screens */
@media (max-width: 480px) {
  footer {
      padding: 10px; /* Reduce padding for smaller screens */
  }
}

/* Media queries for medium-sized screens */
@media (max-width: 768px) {
  footer {
      padding: 15px; /* Adjust padding for medium-sized screens */
  }
}

/* Media queries for larger screens */
@media (max-width: 1024px) {
  footer {
      padding: 20px; /* Reset padding for larger screens */
  }
}