/* DataThemes.css */
@import url('https://fonts.googleapis.com/css2?family=Castoro:ital@0;1&display=swap');
.container {
    display: flex;
    background-color: black;
    color: black; /* Text color */
    font-family: "Castoro", serif;
  }
.containerData{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 150vh; /* Adjust height as needed */
    background-color: black; /* Set background color to black */
    color: white; /* Set text color to white */
  }
  
  .contentData {
    text-align: center;
  }
  .hero-headingData{
    color: white; /* Change text color to white */
    font-family: "Castoro", serif;
    font-size: 40px;
    text-align: center;
  }

  /* Media queries for smaller screens */
@media (max-width: 480px) {
  
  .containerData {
      height: auto; /* Reset height for smaller screens */
      
  }

  .hero-headingData {
      font-size: 30px; /* Reduce font size for smaller screens */
  }
}

/* Media queries for medium-sized screens */
@media (max-width: 768px) {
  .hero-headingData {
      font-size: 35px; /* Adjust font size for medium-sized screens */
  }
}

/* Media queries for larger screens */
@media (max-width: 1024px) {
  .hero-headingData {
      font-size: 40px; /* Adjust font size for larger screens */
  }
}