@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(to bottom, rgb(56, 104, 130) 2%, black 50%);
    position: sticky;
    top: 0;
    text-decoration: none;
  }

nav .logo {
  font-size: 1.5rem;
  margin: 2.5rem;
  font-weight: bold;
  text-decoration: none;
  color: white;
  
  
}

nav p {
  font-size: 0.5rem;
  margin: 0.5rem;
  font-weight: bold;
  text-decoration: none;
  color: white;
}
nav ul {
  display: flex;
}
nav ul .open{
display: flex;

}
nav ul li {
  list-style: none;
}
nav ul li a {
  display: block;
  text-decoration: none;
  color: white;
  font-family: "Poppins", sans-serif;
  padding: 1rem;
  margin: 0 0.5rem;
  
}
nav ul li a:hover {
  background-color: coral;
}
nav .hamburger-menu {
  display: none;
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}
nav .hamburger-menu span {
  height: 0.4rem;
  width: 100%;
  background-color: white;
  border-radius: 0.2rem;
}

@media(max-width: 480px){
    nav .hamburger-menu {
        display: flex;
    }
    nav{
        flex-direction: column;
        align-items: start;
    }
    nav ul{
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
    }
    nav ul li{
        width: 100%;
        text-align: center;
    }
    nav ul li a{
        margin: auto;
        text-align: center;
    }
}

.hamburger-menu {
  display: none;
  cursor: pointer;
}

.hamburger-menu span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 5px auto;
  transition: all 0.3s ease-in-out;
}

.hamburger-menu.open span:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger-menu.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open span:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* Media query for smaller screens */
@media (max-width: 480px) {
  .hamburger-menu {
    display: block;
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem; /* Adjust padding for smaller screens */
  }

  nav ul {
    display: none;
    width: 100%;
  }

  nav ul.open {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    width: 100%;
    text-align: center;
  }

  nav ul li a {
    margin: auto;
    text-align: center;
  }
}

@media (max-width: 1024px) {
  .hamburger-menu {
    display: block;
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem; /* Adjust padding for smaller screens */
  }

  nav ul {
    display: none;
    width: 100%;
  }

  nav ul.open {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    width: 100%;
    text-align: center;
  }

  nav ul li a {
    margin: auto;
    text-align: center;
  }
}
