@import url('https://fonts.googleapis.com/css2?family=Castoro:ital@0;1&display=swap');

.container {
    display: flex;
    background-color: black;
    color: black; /* Text color */
    font-family: "Castoro", serif;
  }
  
  .content {
    flex: 1;
    padding: 20px;
    margin-right: 20px; /* Add margin to the right side */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; /* Center text and button horizontally */

  }
  
  .image {
    flex: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px; /* Add margin to the left side */
    padding-bottom: 40px;
  }
  
  .image img {
    width: 550px; /* Set width */
    height: 350px; /* Set height */
    border-radius: 20px; /* Add rounded corners */
  }

  
  
  .hero-heading {
    color: white; /* Change text color to white */
    font-family: "Castoro", serif;
    font-size: 40px;
    text-align: center;
  }
  
  .hero-para {
    color: white; /* Change text color to white */
    font-family: "Castoro", serif;
    font-size: 30px;
    text-align: center;
  }
  
  .data-button {
    background-color: #0f39e1; /* Background color for the button */
    color: white; /* Text color for the button */
    padding: 10px 20px; /* Increase padding for the button */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Castoro", serif; /* Apply font family to the button */
    font-size: 16px; /* Increase text size for the button */
    font-weight: bold;
    margin-top: 15px; /* Add margin to the top of the button */
    align-items: center;
  }
  
  .data-button:hover {
    background-color: #405d9b; /* Change background color on hover */
  }

 /* Media queries for smaller screens */
@media (max-width: 480px) {
  .container {
      flex-direction: column;
  }

  .content,
  .image {
      margin-right: 0; /* Remove right margin on smaller screens */
      margin-bottom: 20px; /* Add margin between sections on smaller screens */
  }

  .image {
      padding-bottom: 20px; /* Reduce padding at the bottom of the image */
  }
}

/* Media queries for medium-sized screens */
@media (max-width: 768px) {
  .container {
      flex-direction: column;
  }

  .content,
  .image {
      margin-right: 0; /* Remove right margin on medium-sized screens */
      margin-bottom: 20px; /* Add margin between sections on medium-sized screens */
  }

  .image {
      padding-bottom: 20px; /* Reduce padding at the bottom of the image */
  }
}

/* Media queries for larger screens */
@media (max-width: 1024px) {
  .container {
      flex-direction: row;
  }

  .content,
  .image {
      flex: 1; /* Equal width for content and image on larger screens */
      margin-right: 20px; /* Add margin between content and image */
      margin-bottom: 0; /* Remove bottom margin */
  }

  .image {
      padding-bottom: 0; /* Remove padding at the bottom of the image */
  }
}
