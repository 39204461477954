@import url('https://fonts.googleapis.com/css2?family=Castoro:ital@0;1&display=swap');
.card-container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
  }
  
  .card {
    width: 200px;
    height: 200px;
    background-color: white;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }
  
  .card p {
    color: rgb(0, 0, 0);
    font-size: 18px;
    text-align: center;
    font-family: "Castoro", serif;
  }

  /* Media queries for smaller screens */
@media (max-width: 480px) {
  .card-container {
      grid-template-columns: repeat(1, 1fr); /* Change to single column layout */
      grid-gap: 3px; /* Reduce gap between cards */
      margin: 5px;
  }

  .card {
      width: 100%; /* Set cards to full width */
  }
}

/* Media queries for medium-sized screens */
@media (max-width: 768px) {
  .card-container {
      grid-template-columns: repeat(2, 1fr); /* Change to two-column layout */
  }
}

/* Media queries for larger screens */
@media (max-width: 1024px) {
  .card-container {
      grid-template-columns: repeat(3, 1fr); /* Reset to three-column layout */
  }
}
