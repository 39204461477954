@import url('https://fonts.googleapis.com/css2?family=Castoro:ital@0;1&display=swap');

.contact-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form h2 {
    text-align: center;
    color: rgb(0, 0, 0);
    font-family: "Castoro", serif;
  }
  
  .input-field {
    margin-bottom: 20px;
    font-family: "Castoro", serif;
    color: #000000;
  }
  
  .input-field label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .input-field input[type="text"],
  .input-field input[type="email"],
  .input-field textarea {
    width: calc(100% - 12px);
    padding: 8px;
    border-radius: 3px;
    border: 1px solid #000000;
    font-family: "Castoro", serif;
    color: #000000;

  }
  
  .input-field textarea {
    height: 100px;
  }
  
  button {
    background-color: #0f39e1; ;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    margin-left: 0px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Castoro", serif;
  }
  
  button:hover {
    background-color: #0056b3;
  }

  /* Media queries for smaller screens */
@media (max-width: 480px) {
  .contact-form {
      max-width: 300px;
      padding: 20px;
      
  }
}

/* Media queries for medium-sized screens */
@media (max-width: 768px) {
  .contact-form {
      max-width: 500px;
      padding: 30px;
  }
}

/* Media queries for larger screens */
@media (max-width: 1024px) {
  .contact-form {
      max-width: 600px;
      padding: 40px;
  }
}