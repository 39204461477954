@import url('https://fonts.googleapis.com/css2?family=Castoro:ital@0;1&display=swap');

.container {
    display: flex;
    background-color: black;
    color: black; /* Text color */
    font-family: "Castoro", serif;
  }
  
  .content {
    flex: 1;
    padding: 20px;
    margin-right: 20px; /* Add margin to the right side */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; /* Center text and button horizontally */

  }
  
  .image {
    flex: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px; /* Add margin to the left side */
    padding-bottom: 40px;
  }
  
  .image img {
    width: 400px; /* Set width */
    height: 300px; /* Set height */
    border-radius: 20px; /* Add rounded corners */
  }

  
  
  .hero-heading {
    color: white; /* Change text color to white */
    font-family: "Castoro", serif;
    font-size: 40px;
    text-align: center;
  }
  
  .hero-para {
    color: white; /* Change text color to white */
    font-family: "Castoro", serif;
    font-size: 20px;
    text-align: center;
  }

  /* Media queries for smaller screens */
@media (max-width: 480px) {
  .image img {
      width: 300px; /* Reduce image width for smaller screens */
      height: 225px; /* Reduce image height for smaller screens */
  }

  .hero-heading {
      font-size: 30px; /* Reduce font size for smaller screens */
  }

  .hero-para {
      font-size: 16px; /* Reduce font size for smaller screens */
  }
}

/* Media queries for medium-sized screens */
@media (max-width: 768px) {
  .container {
      flex-direction: column; /* Change container direction for medium-sized screens */
  }

  .content,
  .image {
      margin-right: 0; /* Remove right margin for medium-sized screens */
      margin-bottom: 20px; /* Add margin between sections for medium-sized screens */
  }

  .image {
      padding-bottom: 20px; /* Reduce padding at the bottom of the image for medium-sized screens */
  }
}

/* Media queries for larger screens */
@media (max-width: 1024px) {
  .content,
  .image {
      flex: 1; /* Equal width for content and image on larger screens */
      margin-right: 20px; /* Add margin between content and image on larger screens */
      margin-bottom: 0; /* Remove bottom margin on larger screens */
  }

  .image {
      padding-bottom: 0; /* Remove padding at the bottom of the image on larger screens */
  }
}